import { useState } from "react";
import axios from "axios";
import { UserAuth } from "../../../context/AuthContext";

const SubjectCard = ({
  userId,
  studentId,
  gradeId,
  subjectData,
  subjectId
}) => {
  const { user } = UserAuth();
  const [newChapter, setNewChapter] = useState("");
  const [isAddingChapter, setIsAddingChapter] = useState(false);
  const [chapters, setChapters] = useState(subjectData.chapters || {});

  const totalLessons = Object.values(chapters).reduce(
    (total, chapter) => total + (chapter.lessons ? Object.keys(chapter.lessons).length : 0),
    0
  );

  const completedLessons = Object.values(chapters).reduce(
    (completed, chapter) =>
      completed +
      (chapter.lessons
        ? Object.values(chapter.lessons).filter((lesson) => lesson.isComplete === true).length
        : 0),
    0
  );

  const handleCardClick = () => {
    window.location.href = `/subject/${studentId}/${gradeId}/${subjectData.id}`;
  };

  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the parent div
  };

  const saveNewChapter = async (e) => {
    e.stopPropagation(); // Stop event propagation
    if (!newChapter) return;

    try {
      const response = await axios.post(
        `/api/add-new-chapter/`,
        {  
          childId: studentId, 
          gradeId: gradeId, 
          subjectId: subjectId,
          newChapter: newChapter
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": user?.accessToken,
          },
        }
      );

      if (response.data.code === 200) {
        // Update the chapters state with the new chapter
        const newChapterData = response.data.chapter;
        setChapters((prevChapters) => ({
          ...prevChapters,
          [newChapterData.id]: newChapterData,
        }));

        // Show success alert
        alert(`New Chapter: "${newChapterData.name}" added successfully!`);

        // Reset the input field and hide the form
        setNewChapter("");
        setIsAddingChapter(false);
      }
    } catch (error) {
      console.error("Error adding new chapter:", error);
      alert("Failed to add new chapter. Please try again.");
    }
  };

  const handleAddChapterClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    setIsAddingChapter(true);
  };

  const handleCancelClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    setIsAddingChapter(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      saveNewChapter(e);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          padding: "10px",
          borderRadius: "10px", // Rounded corners
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow around all sides
          color: "black", // Ensures text is readable
          fontFamily: "Acme, cursive", // Font style
          transition: "box-shadow 0.3s, background-color 0.3s, color 0.3s",
          cursor: "pointer", // Change cursor to pointer on hover
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = `
          0 0 4px #ff0080, 
          0 0 4px #ffbf00, 
          0 0 4px #80ff00, 
          0 0 16px #00ffff, 
          0 0 12px #ff00ff`;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
        }}
        onClick={handleCardClick} // Handle card click
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <b style={{ fontSize: "1.0em" }}>
            {subjectData.name}{" "}
            <span style={{ fontSize: "0.8em" }}>
              ({completedLessons} out of {totalLessons} lessons completed)
            </span>
          </b>

          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <i
              className="menu-icon fa fa-trash"
              style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
              title="Delete this Topic"
              onClick={(e) => {
                handleIconClick(e);
                // alert("Delete clicked!");
              }}
            ></i>
            <a
              href={`/subject/${studentId}/${gradeId}/${subjectData.id}`}
              style={{ textDecoration: "underline", fontSize: "0.8em" }}
              onClick={handleIconClick}
            >
              <i
                className="menu-icon fa fa-arrow-right"
                style={{ fontSize: "15px", color: "gray", cursor: "pointer" }}
                title="Open this Subject"
              ></i>
            </a>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <span style={{ fontSize: "0.8em", fontWeight: "bold", marginBottom: "4px" }}>
              Progress: {((completedLessons / totalLessons) * 100).toFixed(1)}%
            </span>
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "12px",
              borderRadius: "6px",
              backgroundColor: "#e0e0e0",
              overflow: "hidden",
              boxShadow: "inset 0 0 5px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                width: `${(completedLessons / totalLessons) * 100}%`,
                height: "100%",
                background: "linear-gradient(90deg, #4caf50, #8bc34a)",
                transition: "width 0.3s ease",
              }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexGrow: 1,
            }}
          >
            <span style={{ marginRight: "5px" }}>Topics:</span>
            {Object.entries(chapters)
              .sort(([, a], [, b]) => a.order - b.order) // Sort chapters by order
              .map(([chapterId, chapterData]) => (
                <div
                  key={chapterId}
                  style={{
                    margin: "1px 2px",
                    padding: "2px 3px",
                    backgroundColor: "#fdfdfd",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "0.7em",
                    display: "inline-block",
                  }}
                >
                  {chapterData?.name || "Unnamed Chapter"}
                </div>
              ))}
          </div>
        </div>

        {!isAddingChapter ? (
          <button
            onClick={handleAddChapterClick}
            className="btn btn-primary"
            style={{ margin: "10px 0" }}
          >
            Add New Chapter
          </button>
        ) : (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", margin: "10px 0" }}>
            <input
              type="text"
              value={newChapter}
              onChange={(e) => setNewChapter(e.target.value)}
              onKeyPress={handleInputKeyPress}
              onClick={(e) => e.stopPropagation()} // Stop propagation for input clicks
              className="form-control"
              placeholder="Describe what you want to tech in the new topic..."
            />
            <button onClick={saveNewChapter} className="btn btn-success">
              Save
            </button>
            <button onClick={handleCancelClick} className="btn btn-danger">
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SubjectCard;