import { useState, useEffect } from "react";
// import logo from "./assets/images/logo_picspanel.png";
import logo from './assets/images/edusinaIcons/Logo.png';
import logo2 from "./assets/images/edusinaIcons/Logo.png";
import admin from "./assets/images/admin.jpg";
import { UserAuth } from "../context/AuthContext";
import axios from "axios";
import edusina_icon from './assets/images/edusina-icon.jpg';
import mySchoolsIcon from "../components/assets/images/edusinaIcons/My school.png";
import toolsIcon from "../components/assets/images/edusinaIcons/Tools.png";
import communityIcon from "../components/assets/images/edusinaIcons/community.png";
import "./styles/Header.css";

const Header2 = ({ topicId, currentPage }) => {
  const { logOut, user } = UserAuth();
  const [profilePic, setProfilePic] = useState('');


  // const [onlineUsers, setOnlineUsers] = useState(0);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('/api/online_users'); // Adjust the URL if needed
  //       setOnlineUsers(response.data.online_users);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Fetch data initially

  //   const intervalId = setInterval(() => {
  //     fetchData(); // Fetch data every 30 seconds
  //   }, 30000); // 30 seconds in milliseconds

  //   return () => {
  //     clearInterval(intervalId); // Clean up the interval on unmount
  //   };
  // }, []);


  useEffect(() => {
    // console.log("USER PHOTO URL:: ",user.photoURL);
    setProfilePic(user.photoURL);
  }, [user]);

  const handleSignOut = async () => {
    try {
      await logOut();
      // console.log("Loggged out");
    } catch (error) {
      console.log(error);
    }
  };

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <header id="header" className="header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="col-auto d-md-none">
          <div className="dropdown">
            <a
              href="#"
              className="active"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-bars" style={{ color: '#ff602e' }}></i>
            </a>
            <div className="dropdown-menu">
              <ul className="mobile-menu-list"> {/* Add a <ul> for better structure */}
                <li className={currentPage === 'home' ? 'active' : ''}>
                  <a href="/home" className="mobile-menu-item">
                    <img src={mySchoolsIcon} className="mobile-menu-icon" alt="My Schools" />
                    <span>My Schools</span>
                  </a>
                </li>
                <div class="dropdown-divider"></div>
                <li className={currentPage === 'tools' ? 'active' : ''}>
                  <a href="/ai-toolbox" className="mobile-menu-item">
                    <img src={toolsIcon} className="mobile-menu-icon" alt="AI Tools" />
                    <span>AI Tools</span>
                  </a>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <a href="#" className="mobile-menu-item">
                    <img src={communityIcon} className="mobile-menu-icon" alt="Community" />
                    <span>Community</span> <i className="fa fa-lock" style={{ marginLeft: '5px' }}></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <a className="navbar-brand" href="/home">
          {/* <b>EduSina</b> */}
          <img src={logo} alt="Logo" style={{ height: "50px" }} />

        </a>


        <div className="user-area dropdown">
          <a
            href="#"
            className="dropdown-toggle active"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              className="user-avatar rounded-circle" style={{ padding: '5px' }}
              src={user.photoURL}
              alt={user?.displayName || ''}
            />
          </a>

          <div className="user-menu dropdown-menu" >
            <a className="nav-link" href="#">
              {' '}
            </a>

            <a className="nav-link" href={`/topics`} >
              <i className="fa fa-arrow-left"></i>Back
            </a>
            <a className="nav-link" href={`#`} onClick={handleSignOut}>
              <i className="fa fa-power-off"></i>Log Out
            </a>

          </div>
        </div>
      </div>
    </header>
  );

};

export default Header2;
