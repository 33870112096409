import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import HomeLeftPanel from "../../components/HomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom"; // for routing
import StudentCard from "../../components/cards/StudentCard";

function Home() {
    const navigate = useHistory();
    const { logOut, user } = UserAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [students, setStudents] = useState({});

    const isStudentsEmpty = students && typeof students === "object" && Object.keys(students).length === 0;

    useEffect(() => {
        const fetchCurriculum = async () => {
            try {
                const response = await axios.get(`/api/user/`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                });

                const studentsData = response.data?.children || {};
                setStudents(studentsData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching curriculum::", err);
            } finally {
                setLoading(false);
            }
        };

        fetchCurriculum();
    }, [user]);

    const handleDeleteTopic = async (topicId) => {
        try {
            await axios.delete(`/api/child/${topicId}`, {
                headers: {
                    "x-access-tokens": user?.accessToken,
                },
            });
            // Remove the deleted student from the state
            setStudents((prevStudents) => {
                const updatedStudents = { ...prevStudents };
                delete updatedStudents[topicId];
                return updatedStudents;
            });
        } catch (error) {
            console.error("Error deleting topic:", error);
        }
    };

    const handleClick = (studentId) => {
        navigate.push(`/student/${studentId}`);
    };

    return (
        <div id="right-panel" className="right-panel" style={{ backgroundColor: "white" }}>
            <div className="content">
                <div className="animated fadeIn">
                    <Header currentPage={"home"}/>
                    <HomeLeftPanel currentPage={"home"} />

                    {!loading && isStudentsEmpty && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100vh",
                            }}
                        >
                            <div style={{ padding: "30px", textAlign: "center", marginTop: "-30vh", fontFamily: "Acme, cursive", fontSize: "18px" }}>
                                Hi {user.displayName}, this is your dashboard where you can create or access up to 3 separate homeschools.
                                <br />
                                You can access each of your schools separately to manage curriculum, schedules, student info, grades, lesson plans, and more.
                            </div>
                            <button
                                style={{
                                    backgroundColor: "#000",
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    padding: "10px 20px",
                                    border: "none",
                                    cursor: "pointer",
                                    fontFamily: "Acme, cursive",
                                    fontSize: "18px",
                                }}
                                onClick={() => {
                                    window.location.href = "/add-new-student";
                                }}
                            >
                                <i className="menu-icon fa fa-plus" style={{ marginRight: "8px" }}></i> Create New School
                            </button>
                        </div>
                    )}

                    <div className="row">
                        {Object.values(students || {}).map((child) => (
                            <StudentCard
                                key={child.id}
                                topicId={child.id}
                                gradeId={Object.keys(child.curriculums.grades)[0]}
                                cardTitle={`${child.name}'s school`}
                                onClickTopic={(topicId, gradeId) => {
                                    window.location.href = "/student/" + topicId + "/grade/"+gradeId;
                                }}
                                onDeleteTopic={handleDeleteTopic}
                            />
                        ))}
                    </div>

                    <div className="row">
                        <div className={"col-md-2"}>
                            <button
                                style={{
                                    backgroundColor: "#5A5F99",
                                    color: "white",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                    fontFamily: "Acme, cursive",
                                    fontSize: "18px",
                                }}
                                onClick={() => {
                                    window.location.href = "/add-new-student";
                                }}
                            >
                                <b>+</b> Create New School
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
