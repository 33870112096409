import React, { useState, useEffect } from 'react';
import axios from "axios";
import { PuffLoader } from "react-spinners";
import LessonContent from './LessonContent';
import TeachingGuideline from './TeachingGuideline';
import Assignments from './Assignments';
import Resources from './Resources';

const Quiz = () => <div style={{ padding: "20px" }}>This is the Quiz.</div>;

const Lesson = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
    const [lessonData, setLessonData] = useState(null);

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(
                    `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": userId,
                        },
                    }
                );
                setLessonData(response.data);
                console.log("lessonDATA:", response.data);
            } catch (err) {
                console.error(err.message || "An error occurred while fetching the lesson.");
            }
        };

        fetchLesson();
    }, [childId, gradeId, subjectId, chapterId, lessonId, userId]);

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ["Lesson Content", "Teaching Guideline", "Assessment", "Resources"];

    const tabComponents = [
        { Component: LessonContent, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        { Component: TeachingGuideline, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        { Component: Assignments, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
        { Component: Resources, props: { userId, childId, gradeId, subjectId, chapterId, lessonId } },
    ];

    const { Component: ActiveComponent, props } = tabComponents[activeTab];

    return (
        <>
            {lessonData ? (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}>
                    <div style={{ 
                        display: "flex", 
                        gap: "0", 
                        alignItems: "flex-end", 
                        marginTop: "20px", 
                        borderBottom: "2px solid #ddd",
                    }}>
                        {tabs.map((tab, index) => (
                            <span
                                key={index}
                                onClick={() => setActiveTab(index)}
                                style={{
                                    flexGrow: 1,
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    textAlign: 'center',
                                    borderRadius: "10px 10px 0 0",
                                    backgroundColor: activeTab === index ? "#000" : "transparent",
                                    color: activeTab === index ? "white" : "black",
                                    border: activeTab === index ? "2px solid #000" : "2px solid transparent",
                                    borderBottom: activeTab === index ? "2px solid transparent" : "2px solid #ddd",
                                    fontWeight: activeTab === index ? "bold" : "normal",
                                    transition: "background-color 0.3s, color 0.3s, border 0.3s",
                                    position: "relative",
                                    top: activeTab === index ? "2px" : "0",
                                }}
                                onMouseEnter={(e) => {
                                    if (activeTab !== index) {
                                        e.currentTarget.style.backgroundColor = "#f0f0f0";
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (activeTab !== index) {
                                        e.currentTarget.style.backgroundColor = "transparent";
                                    }
                                }}
                            >
                                {tab}
                            </span>
                        ))}
                    </div>

                    {/* Render the active tab's content */}
                    <div style={{ 
                        padding: "20px", 
                        borderRadius: "0 0 10px 10px",
                        backgroundColor: "#fff",
                    }}>
                        <ActiveComponent {...props} />
                    </div>
                </div>

            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "50px",
                            padding: "5px",
                            borderRadius: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black",
                            fontFamily: "Acme, cursive",
                        }}
                    >
                        <PuffLoader color="#ff602e" loading={true} size={45} />
                        <span>Creating personalized content for your child with AI.</span>
                        <span>Please wait a moment...</span>
                    </div>
                </>
            )}
        </>
    );
};

export default Lesson;