import { useState, useEffect } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";

const StudentCard = ({
  topicId,
  gradeId,
  cardTitle,
  numOfColumns,
  onClickTopic,
  onDeleteTopic,
  onEditTopicTitle
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // console.log("GRADE ID: ", gradeId);
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = () => {
    setShowDeleteConfirmation(false);
    onDeleteTopic(topicId);
  };


  const [showEditModal, setShowEditModal] = useState(false);
  const [editedTopicName, setEditedTopicName] = useState(cardTitle);

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleInputChange = (event) => {
    setEditedTopicName(event.target.value);
  };

  const handleSave = () => {
    // alert(editedTopicName);
    // Call a function to save the edited topic name
    onEditTopicTitle(topicId, editedTopicName);

    // Close the modal
    setShowEditModal(false);
  };

  const handleCancel = () => {
    // Reset the edited topic name and close the modal
    setEditedTopicName(cardTitle);
    setShowEditModal(false);
  };


  return (
    <div className={"col-md-" + numOfColumns}>
      <div className="card">
        <button
          type="button"
          className="btn-sm"
          style={{ position: "absolute", color:'white', top: "10px", right: "10px",  fontSize:'11px', border:'none', backgroundColor:'#cacaca' }}
          onClick={handleDelete}
        >
          <i className="fa fa-trash-o"></i>
          {/* X fa-trash-o */}
        </button>

        {/* <button
          type="button"
          className="btn-sm"
          style={{ position: "absolute", top: "10px", left: "10px", fontSize:'11px', border:'none' }}
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>
        </button> */}

        {showDeleteConfirmation && (
          <div className="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Deletion</h5>
                  {/* <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowDeleteConfirmation(false)}
                  ></button> */}
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this topic?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={confirmDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit modal */}
        {showEditModal && (
          <div className="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Topic Name</h5>
                  {/* <button
                    type="button"
                    className="btn-close"
                    onClick={handleCancel}
                  ></button> */}
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    value={editedTopicName}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}


        <img className="card-img-top" src={`https://annarborfamily-com-images.s3.amazonaws.com/wp-content/uploads/2017/08/home-school.png`} alt={cardTitle} />
        <div className="card-body" >
          <h4 className="card-title mb-3" style={{ fontFamily: "Acme, cursive", fontSize:'19px', display: 'flex',
              justifyContent: 'center', }}>{cardTitle}</h4>
          <button
            type="button"
            onClick={() => {
              onClickTopic(topicId, gradeId);
            }}
            // className={"btn btn-warning btn-sm"}
            style={{
              backgroundColor: "#5a5f99", 
              borderColor: "#5a5f99",
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              padding: '2px 3px',
              border: 'none',
              cursor: 'pointer',
              fontFamily: "Acme, cursive",
              fontSize: '18px',
              width:'100%'
            }}
          >
            <i className="fa fa-arrow-circle-o-right"></i>&nbsp; Explore
          </button>
        </div>
      </div>
    </div>
  );
};

StudentCard.defaultProps = {
  cardTitle: "Card Title",
  cardDescription: "Card Description",
  numOfColumns: 3, //default set to 3. Possible 1,2,3...,12
  buttonStyle: "primary",
  indexingTaskId: null,
};

export default StudentCard;
