import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import StudentHomeLeftPanel from "../../components/StudentHomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import SubjectCard from "../curriculum/components/SubjectCard";

function Student() {
    const { logOut, user } = UserAuth();
    let { studentId, gradeId } = useParams();
    const [childId, setChildId] = useState(studentId);
    const [curriculum, setCurriculum] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [studentName, setStudentName] = useState("");
    const [scheduleData, setScheduleData] = useState([]);
    const [newSubject, setNewSubject] = useState("");
    const [isAddingSubject, setIsAddingSubject] = useState(false);
    const [generatedTopics, setGeneratedTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState({});

    const fetchCurriculum = async () => {
        try {
            const response = await axios.get(`/api/get-child/${childId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": user?.accessToken,
                },
            });
            const childData = response?.data?.child;
            const curriculums = childData?.curriculums;
            if (curriculums) {
                setCurriculum(curriculums.grades);
                setStudentName(childData.name);
            } else {
                console.warn("Invalid or missing curriculum data:", curriculums);
                setCurriculum(null);
            }
        } catch (err) {
            console.error("Error fetching curriculum:", err);
            setError(err?.response?.data?.message || "Failed to fetch curriculum");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurriculum();
    }, [user, childId]);

    const generateSubject = async () => {
        if (!newSubject) return;

        const response = await axios.get(`/api/gen-subject?name=${newSubject}`);
        const generated = response.data.curriculum;

        // Initialize selectedTopics with all lessons selected
        const initialSelectedTopics = {};
        generated.forEach(subject => {
            subject.chapters.forEach(chapter => {
                chapter.lessons.forEach(lesson => {
                    initialSelectedTopics[lesson] = true;
                });
            });
        });

        setGeneratedTopics(generated);
        setSelectedTopics(initialSelectedTopics);
    };

    const toggleChapterSelection = (chapter, isSelected) => {
        const updatedSelectedTopics = { ...selectedTopics };

        chapter.lessons.forEach(lesson => {
            updatedSelectedTopics[lesson] = isSelected;
        });

        setSelectedTopics(updatedSelectedTopics);
    };

    const saveNewSubject = async () => {
        const selectedLessons = generatedTopics.map(subject => ({
            name: subject.name,
            chapters: subject.chapters.map(chapter => ({
                name: chapter.name,
                lessons: chapter.lessons.filter(lesson => selectedTopics[lesson])
            })).filter(chapter => chapter.lessons.length > 0) // Filter out chapters with no selected lessons
        })).filter(subject => subject.chapters.length > 0); // Filter out subjects with no selected chapters
        
        console.log("New Subject:: ", selectedLessons[0]);

        try {
            const response = await axios.post(
                `/api/add-new-subject-new/`,
                {  
                    childId: childId, 
                    gradeId: gradeId, 
                    newSubjects: selectedLessons[0]
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                }
            );
            console.log(response);
            
    
            await fetchCurriculum();
    
            setIsAddingSubject(false);
            setNewSubject("");
            setGeneratedTopics([]);
        } catch (error) {
            console.error("Error adding new subject:", error);
        }
    };
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <div id="right-panel" className="right-panel" style={{ backgroundColor: "#fefefe" }}>
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <StudentHomeLeftPanel currentPage={"curriculum"} studentName={studentName} studentId={studentId} />

                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ fontFamily: "Acme, cursive", fontSize: "25px" }}>{studentName}'s Curriculum</span>
                                </div>

                                {!isAddingSubject ? (
                                    <button onClick={() => setIsAddingSubject(true)} className="btn btn-primary" style={{ margin: "10px 0" }}>Add New Subject</button>
                                ) : (
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", margin: "10px 0" }}>
                                        <input type="text" value={newSubject} onChange={e => setNewSubject(e.target.value)} className="form-control" placeholder="Describe what you want to teach in this new subject..." />
                                        <button onClick={generateSubject} className="btn btn-success">Generate</button>
                                        <button onClick={() => setIsAddingSubject(false)} className="btn btn-danger">Cancel</button>
                                    </div>
                                )}

                                {generatedTopics.length > 0 && (
                                    <div style={{ margin: "10px 0" }}>
                                        {generatedTopics.map(subject => (
                                            <div key={subject.name}>
                                                <h4>{subject.name}</h4>
                                                {subject.chapters.map(chapter => (
                                                    <div key={chapter.name}>
                                                        <h5>
                                                            <input
                                                                type="checkbox"
                                                                checked={chapter.lessons.every(lesson => selectedTopics[lesson])}
                                                                onChange={(e) => toggleChapterSelection(chapter, e.target.checked)}
                                                            />
                                                            {chapter.name}
                                                        </h5>
                                                        {/* {chapter.lessons.map(lesson => (
                                                            <div key={lesson} style={{ marginLeft: "20px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!!selectedTopics[lesson]}
                                                                    onChange={() => toggleChapterSelection(chapter, !selectedTopics[lesson])}
                                                                /> {lesson}
                                                            </div>
                                                        ))} */}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                        <button onClick={saveNewSubject} className="btn btn-primary" style={{ marginTop: "10px" }}>Save</button>
                                    </div>
                                )}

                                {Object.entries(curriculum || {}).map(([gradeId, gradeData]) => (
                                    <div key={gradeId}>
                                        {Object.entries(gradeData?.subjects || {}).sort(([, a], [, b]) => a.order - b.order).map(([subjectId, subjectData]) => (
                                            <div key={subjectId}>
                                                <SubjectCard studentId={studentId} gradeId={gradeId} subjectData={subjectData} subjectId={subjectId}/>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Student;