import React from "react";
import logo_edusina from "../../components/assets/images/vertical_bg_removed.png";
import styles from './css/css/style.module.css';
import { UserAuth } from "../../context/AuthContext";

function Header({ showLoginBtn = true }) {
  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <header id="header-section">
      <nav className={`${styles['navbar-expand-lg']} ${styles['pl-3']} ${styles['pl-sm-0']} ${styles['navbar']} `} id="navbar" style={{ height: "30px", backgroundColor: 'white' }}>
        <div className={`${styles['container']} ${styles['d-flex']} ${styles['justify-content-between']} ${styles['align-items-center']}`}>
          <div className={`${styles['navbar-brand-wrapper']} ${styles['d-flex']} ${styles['align-items-center']}`}>
            <a href="https://edusina.com" target="_blank" rel="noopener noreferrer">
              <img src={logo_edusina} style={{ height: "55px" }} alt="" />
            </a>
          </div>
          <div className={`${styles['collapse']} ${styles['navbar-collapse']} ${styles['navbar-menu-wrapper']}`}
            id="navbarSupportedContent">
            <ul className={`${styles['navbar-nav']} ${styles['align-items-lg-center']} ${styles['align-items-start']} ${styles['ml-auto']}`}>
              <li className={`${styles['d-flex']} ${styles['align-items-center']} ${styles['justify-content-between']} ${styles['pl-4']} ${styles['pl-lg-0']}`}>
                <div className={styles['navbar-collapse-logo']}>
                  <img src={logo_edusina} style={{ height: "55px" }} alt="" />
                </div>
              </li>
            </ul>
          </div>
          {showLoginBtn && (
                <div className={`${styles['nav-item']} ${styles['btn-contact-us']} ${styles['pl-4']} ${styles['pl-lg-0']}`}>
                  <button
                    className={`${styles['btn']} ${styles['btn-warning']}`}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => {
                      // window.open("/login/");
                      // window.location.href = "/login/";
                      handleGoogleSignIn();
                    }}
                    style={{ backgroundColor: '#fff', borderColor: "#fff", color: 'black', fontFamily: "Merriweather, cursive", }}
                  >
                    Login
                  </button>
                </div>
              )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
