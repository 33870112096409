import React, { useState, useEffect } from 'react';
import axios from "axios";
import { PuffLoader } from "react-spinners";
import ContextualChat from '../../aiToolBox/tools/ContextualChat';

const LessonContent = ({ userId, childId, gradeId, subjectId, chapterId, lessonId }) => {
    const [lessonData, setLessonData] = useState(null);
    const [isPrompting, setIsPrompting] = useState(false); // State to toggle input box
    const [aiPrompt, setAiPrompt] = useState(""); // State to store user's prompt
    const [isLoading, setIsLoading] = useState(false); // State to handle loading during regeneration

    // Fetch lesson data on component mount
    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const response = await axios.get(
                    `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": userId,
                        },
                    }
                );
                setLessonData(response.data);
                console.log("lessonDATA:", response.data);
            } catch (err) {
                console.error(err.message || "An error occurred while fetching the lesson.");
            }
        };

        fetchLesson();
    }, [childId, gradeId, subjectId, chapterId, lessonId, userId]);

    // Function to handle content regeneration
    const handleRegenerateContent = async () => {
        setIsLoading(true); // Show loading state
        setLessonData((prev) => ({
            ...prev,
            lesson_text: "<h5></h5>",
        }));

        try {
            const response = await axios.post(
                `/api/regenerate/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                { 
                    prompt: aiPrompt,
                    currentLesson: lessonData.lesson_text
                 },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": userId,
                    },
                }
            );

            setLessonData((prev) => ({
                ...prev,
                lesson_text: response.data.lesson_text,
            }));
            setIsPrompting(false); // Hide input box after regeneration
        } catch (err) {
            alert("Failed to generate content: " + (err.message || "Unknown error"));
        } finally {
            setIsLoading(false); // Hide loading state
        }
    };

    return (
        <>
            {lessonData ? (
                <div style={{ display: "flex", flexDirection: "column", padding: "0 20px" }}>
                    {/* Edit Lesson Button */}
                    <button
                        style={{
                            backgroundColor: 'green',
                            color: 'white',
                            width: '250px',
                            marginBottom: '10px',
                            borderRadius: '5px',
                            border: 'none',
                            padding: '8px 12px',
                            cursor: 'pointer',
                        }}
                        onClick={() => setIsPrompting(!isPrompting)}
                    >
                        {isPrompting ? "Close" : "Edit Lesson with Prompt"}
                    </button>

                    {/* Input Box and Regenerate Button */}
                    {isPrompting && (
                        <div style={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                            <input
                                type="text"
                                value={aiPrompt}
                                onChange={(e) => setAiPrompt(e.target.value)}
                                placeholder="Enter your prompt"
                                style={{
                                    marginRight: "10px",
                                    padding: "8px",
                                    flex: 1,
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <button
                                style={{
                                    backgroundColor: 'green',
                                    color: 'white',
                                    padding: '8px 12px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={handleRegenerateContent}
                                disabled={isLoading} // Disable button during loading
                            >
                                {isLoading ? "Regenerating..." : "Regenerate"}
                            </button>
                        </div>
                    )}

                    {/* PuffLoader during regeneration */}
                    {isLoading && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "20px 0",
                            }}
                        >
                            <PuffLoader color="#ff602e" loading={true} size={45} />
                            <span style={{ marginTop: '10px' }}>Regenerating lesson content. Please wait...</span>
                        </div>
                    )}

                    {/* Lesson Content */}
                    <div
                        style={{
                            paddingRight: "100px",
                            fontFamily: "Acme, cursive",
                            paddingLeft: "20px",
                        }}
                        dangerouslySetInnerHTML={{ __html: lessonData.lesson_text }}
                    />

                    {/* Contextual Chat */}
                    <div>
                        <ContextualChat context={lessonData.lesson_text} />
                    </div>
                </div>
            ) : (
                // Initial Loading State
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "50px",
                        padding: "5px",
                        borderRadius: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        fontFamily: "Acme, cursive",
                    }}
                >
                    <PuffLoader color="#ff602e" loading={true} size={45} />
                    <span style={{ marginTop: '10px' }}>Creating personalized content for your child with AI.</span>
                    <span>Please wait a moment...</span>
                </div>
            )}
        </>
    );
};

export default LessonContent;