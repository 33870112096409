import React from "react";
import Header from "./Header";
import "./LandingPage.css";
import addNewStudent from './images/addNewStudent.png';
import landingImage from './images/landingImage.jpeg';
import cur from './images/cur.png';
import sch from './images/sch.png';
import { UserAuth } from "../../context/AuthContext";

function LandingPage(props) {
  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    // backgroundColor: '#f8f8f8',
    padding: '20px',
    borderRadius: '8px',
    flexWrap: "wrap",
  };

  const planStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    width: '30%', // Adjust based on spacing
    textAlign: 'center',
    fontFamily: "Merriweather",
    height: '450px'
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: "Merriweather"
  };

  const descriptionStyle = {
    fontSize: '14px',
    color: '#555',
    marginBottom: '10px',
    fontFamily: "Merriweather"
  };

  const priceStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#2c7d59',
    marginBottom: '10px',
    fontFamily: "Merriweather"
  };

  const featuresStyle = {
    fontSize: '14px',
    color: '#333',
    textAlign: 'left',
    fontFamily: "Merriweather"
  };

  return (
    <>
      <Header />
      <br />
      <div className="banner">
        <div className="fourth-flex" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
            <img
              src={landingImage}
              alt="Description of image"
              style={{
                // width: '50%', // Relative to the parent container
                maxWidth: '450px', // Maximum size for larger screens
                // height: 'auto', // Maintains the aspect ratio
                height: '450px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />

          </div>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
            <div>
              <span style={{ fontFamily: "Merriweather", fontSize: 30 }}>Homeschooling Made <br />Stress-Free with AI</span>
              <br />
              <span style={{ fontFamily: "Merriweather", fontSize: 14 }}>From curriculum planning to progress tracking, <br />we’ve got you covered—so you can focus <br />on what matters most: your child’s growth.</span>
              <br />
              <br />
              <br />
              <button
                className="cta-button"
                style={{ borderRadius: "5px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow = `
                  0 0 14px #ff0080, 
                  0 0 14px #ffbf00, 
                  0 0 14px #80ff00, 
                  0 0 26px #00ffff, 
                  0 0 22px #ff00ff
                `;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
                }}
                onClick={handleGoogleSignIn}
              >
                Create Your Homeschool Now!
              </button>
              <div style={{ padding: '10px' }}>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSca7JJHPYKEoaAHO-owRbOdKnvzHjUO_bVTe5feXyL6lqnPmg/viewform" target="_blank" style={{ fontSize: '14px', fontFamily: 'Merriweather', textDecoration: 'underline' }}>We'd love to hear your feedback!</a>
              </div>
            </div>

          </div>
        </div>

        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '70px' }}>
          <span style={{ fontFamily: "Merriweather", fontSize: 30 }}>Features and Functionalities</span>
        </div>

        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Curriculum Planner</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Effortlessly design and organize your childs education with our user-friendly curriculum planner. Create your Curriculum in seconds just by answering a few questions
            </div>
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Lesson Generator</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Transform your planning process with AI-powered lesson generation. Create tailored, engaging lessons in seconds—saving you time for what matters most
            </div>
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Scheduler</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Keep your homeschooling days stress-free with a dynamic scheduler. Plan lessons, assignments, and activities—all in one place
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Assessment Tools</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Track progress with ease! Design quizzes, assignments, and flashcards that make learning interactive and fun while reinforcing key concepts
            </div>
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Global Curriculum Database</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Access an extensive library of curricula from around the world. Find inspiration or align with educational standards that fit your homeschooling goals
            </div>
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '300px', padding: '10px' }}>
            <div style={{ fontFamily: "Merriweather", fontSize: 20, }}>Learning Resources</div>
            <div style={{ fontFamily: "Merriweather", fontSize: 14, backgroundColor: '#eee', padding: '10px', borderRadius: 10, textAlign: 'center' }}>
              Discover resources designed for homeschooling success—customized to fit your child’s needs and amplified with cutting-edge AI tools
            </div>
          </div>
        </div>

        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '100px', marginBottom: '20px' }}>
          <span style={{ fontFamily: "Merriweather", fontSize: 30 }}>How it Works</span>
        </div>

        <div className="fourth-flex" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>

          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
            <div style={{ width: '300px', textAlign: 'center', backgroundColor: '#eee', padding: '10px', borderRadius: '10px' }}>

              <span style={{ fontFamily: "Merriweather", fontSize: 14, }}>Set up your school and answer a few quick questions to have your curriculum created in seconds. Edit and customize it seamlessly anytime</span>

            </div>
          </div>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
            <img
              src={addNewStudent}
              alt="Description of image"
              style={{
                // width: '50%', // Relative to the parent container
                // maxWidth: '450px', // Maximum size for larger screens
                // height: 'auto', // Maintains the aspect ratio
                height: '450px',
                // borderRadius: '50%',
                objectFit: 'contain',
                borderRadius: '8px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                padding: '3px'
              }}
            />

          </div>

        </div>

        <div className="fourth-flex" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column', marginTop: '50px' }}>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
            <div style={{ width: '300px', textAlign: 'center', backgroundColor: '#eee', padding: '10px', borderRadius: '10px' }}>

              <span style={{ fontFamily: "Merriweather", fontSize: 14, }}>Automatically generate lessons tailored to your curriculum. Customize them to suit your preferences, and easily add quizzes, assignments, and resources to enhance each lesson</span>

            </div>
          </div>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
            <img
              src={cur}
              alt="Description of image"
              style={{
                // width: '50%', // Relative to the parent container
                // maxWidth: '450px', // Maximum size for larger screens
                // height: 'auto', // Maintains the aspect ratio
                height: '450px',
                // borderRadius: '50%',
                objectFit: 'contain',
                borderRadius: '8px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                padding: '10px',
                width: '850px'
              }}

            />

          </div>

        </div>


        <div className="fourth-flex" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column', marginTop: '50px' }}>
          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
            <div style={{ width: '300px', textAlign: 'left', backgroundColor: '#eee', padding: '10px', borderRadius: '10px' }}>

              <span style={{ fontFamily: "Merriweather", fontSize: 14, }}>Keep all your lessons, tests, and events organized in one central scheduler. Sync it with your calendar to stay effortlessly on top of your homeschooling activities</span>

            </div>
          </div>

          <div className="column" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
            <img
              src={sch}
              alt="Description of image"
              style={{
                // width: '50%', // Relative to the parent container
                // maxWidth: '450px', // Maximum size for larger screens
                // height: 'auto', // Maintains the aspect ratio
                height: '450px',
                // borderRadius: '50%',
                objectFit: 'contain',
                borderRadius: '8px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                padding: '10px',
                width: '850px'
              }}
            />

          </div>

        </div>

        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '100px' }}>
          <span style={{ fontFamily: "Merriweather", fontSize: 30 }}>Pricing</span>
        </div>

        <div style={containerStyle}>
          <span style={{ fontFamily: "Merriweather", fontSize: 20 }}>Its Completely Free! Give it a Try Now!</span>

          {/* <div style={planStyle}>
            <div style={titleStyle}>Free Trial</div>
            <div style={priceStyle}>$0</div>
            <div style={descriptionStyle}>
              Discover all premium features for 30 days. No payment required to get started.
            </div>
            
            <div style={featuresStyle}>
              <ul>
                <li>Full access to curriculum planning</li>
                <li>Unlimited lessons planning</li>
                <li>Quizzes and assignments</li>
                <li>Access to all AI tools</li>
              </ul>
            </div>
          </div> */}


          {/* <div style={planStyle}>
            <div style={titleStyle}>Monthly Plan</div>
            <div style={priceStyle}>$15/month</div>
            <div style={descriptionStyle}>
              After your trial, continue your journey for just $15/month.
            </div>
            
            <div style={featuresStyle}>
              <ul>
                <li>Full access to curriculum planning</li>
                <li>Unlimited lessons planning</li>
                <li>Quizzes and assignments</li>
                <li>Access to all AI tools</li>
              </ul>
            </div>
          </div> */}


          {/* <div style={planStyle}>
            <div style={titleStyle}>Annual Plan</div>
            <div style={priceStyle}>$108/year</div>
            <div style={descriptionStyle}>
              Save 40% when you pay annually—equivalent to just $9/month.
            </div>
            
            <div style={featuresStyle}>
              <ul>
                <li>Everything in the Monthly Plan</li>
                <li>Added benefit of significant savings over the year!</li>
              </ul>
            </div>
          </div> */}
        </div>

        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: '100px' }}>
          <span style={{ fontFamily: "Merriweather", fontSize: 30 }}>Contact Us</span>
        </div>
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
          <span style={{ fontSize: 14 }}>We would love to hear from you. Send us an email at <b>ourdoodlepad@gmail.com</b>.<br />Tell us how how we can serve you better.</span>
        </div>

        <br /><br /><br />
      </div>

      <footer className="footer">
        <div className="footer-content">
          Copyright © 2025
          <a href="#" className="footer-link">
            Edusina
          </a>
          All rights reserved.
          <br />
          Created with
          <a href="https://www.bootstrapdash.com/" className="footer-link">
            Bootstrapdash
          </a>
          ,
          <a href="https://www.freepik.com/" className="footer-link">
            Freepik
          </a>
        </div>
      </footer>
    </>
  );
}

export default LandingPage;
